import { createApp } from "vue";
import App from './App.vue'


import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import router from './router'

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckToSlot } from "@fortawesome/free-solid-svg-icons";
import { faHandshakeSimple } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { createHead} from '@vueuse/head';

const head = createHead();

library.add(faCheckToSlot);
library.add(faHandshakeSimple);

createApp(App).use(router, head)
.component("font-awesome-icon", FontAwesomeIcon)
.mount('#app');

