<template>
<div class="row mb-2 pt-5 mt-4">
        <div class="col-12 text-center fs-1 p-4">
          <div><font-awesome-icon class="fs-3" style="color: #00B1DA;" icon="fa-solid fa-handshake-simple" /></div>
          <div  style="margin-top: -20px;">{{title}}</div>
        </div>
      </div>
</template>

<script>
export default {
  name: 'SectionTitle',
  props: {
    title: String
  }
}
</script>

