<template>

  <div class="home">

    <div class="text-white pt-4" style="background-color: #00B1DA; overflow-x: hidden;">
     
    </div>

    <div class="container">
      <div class="row mb-2 pt-5">
        <div class="col-sm-12 col-md-6">
          <p class="fs-5 text-start pt-5">
            It is an honour to represent Reform UK in Cheshire. 
          </p>
          <p class="fs-5 text-start">
            We, as a nation, need to rebuild Britain to improve all of our lives. We need to improve how we are as a nation and also at the community level.
          </p>
          <p class="fs-5 text-start">
            It is my mission to make this happen.
          </p>
        </div>
        <div class="col-sm-12 col-md-6">
          <img class="img-fluid" src="https://jasonmoorcroft.s3.eu-west-2.amazonaws.com/jasebgwhite.png" alt="Jason Moorcroft image"/>
        </div>

      </div>
      <hr>
      <div class="row mb-2 pt-5">
        <div class=" col-xs-12 col-sm-12 col-md-6 col-lg-4">
          <div class="card border-0 mr-2">
            <div class="card-body text-start">
              <p class="fs-5 text-start" style="color: #00B1DA;"><strong>Vote Reform UK</strong></p>
              <p class="fs-1 text-start" style="margin-top: -15px; font-family: 'Neuton Extra Bold';">We can build a
                better future
                together</p>
              <ul class="fs-5 text-start">
                <li>Pay less tax</li>
                <li>Lower bills</li>
                <li>Education system fit for purpose</li>
                <li>Sensible and fair border control</li>
                <li>Greener environment</li>
                <li>Improve NHS outcomes</li>
              </ul>

            </div>
          </div>
        </div>

        <div class="d-md-none d-lg-block col-lg-4">
          <div class="card border-0 mr-2" style="height: 100%;background-color: transparent ;">

            <img src="https://jasonmoorcroft.s3.eu-west-2.amazonaws.com/family.jpg" class="card-img-top" alt="family"
              style="height:100%;">


          </div>
        </div>

        <div class=" col-xs-12 col-sm-12 col-md-6 col-lg-4 pt-2  " style="min-height: 300px;">
          <div class="card text-white border-none mr-2" style="height: 100%; background-color: #00B1DA;">
            <div class="card-body text-start">
              <h5 class="card-title fs-1" style="font-family: 'Neuton Extra Bold';"><font-awesome-icon
                  icon="fa-solid fa-check-to-slot" /> Vote Status</h5>
              <p class="card-text fs-4 pt-4">You can only make a difference if you vote. <br><br>Are you registered to
                vote?</p>

              <div style="position: absolute; bottom: 30px;">
                <a class="card-text fs-6 pt-6" target="_blank"
                  style="text-decoration: none; background-color: white; color: black; padding: 10px;"
                  href="https://www.gov.uk/electoral-register">Check Electoral Register</a>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row mb-2 pt-5" style="margin-top: 100px;">
        <div class="col-12 text-center fs-1 p-4">Big problems. Simple solutions.</div>
        <div class="col-sm-12 col-md-6">
          <div class="row">
            <div class="col-4 p-1">
              <img class="img-thumbnail border-0" src="https://jasonmoorcroft.s3.eu-west-2.amazonaws.com/medical-sm.jpg"
                alt="doctor">
            </div>
            <div class="col-4 p-1">
              <img class="img-thumbnail border-0" src="https://jasonmoorcroft.s3.eu-west-2.amazonaws.com/energy-sm.jpg"
                alt="offshore wind farm">
            </div>
            <div class="col-4 p-1">
              <img class="img-thumbnail border-0" src="https://jasonmoorcroft.s3.eu-west-2.amazonaws.com/worker.jpg"
                alt="man working builder">
            </div>
          </div>
          <div class="row pb-1 pt-2 mt-3 text-start fs-5" style="color: slategrey">
            <div class="col-12">
              <p>As a nation we have so much potential, so much that we should be optimistic about.
                We can make Britain great again.</p>
              <p>To do this, reform is essential in the way our country is run and managed, so it works
                properly for the people. In many areas, just the application of basic common sense
                would be a good start! </p>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item">
              <h2 class="accordion-header fs-5" id="flush-headingOne">
                <button class="accordion-button  fs-5" type="button" data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                  NHS Healthcare
                </button>
              </h2>
              <div id="flush-collapseOne" class="accordion-collapse collapse show fs-5" aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample">
                <div class="accordion-body fs-5 text-start">The NHS is possibly the most loved
                  healthcare system in the world. Being
                  free at the point of delivery is at its core
                  and must always continue.
                </div>
              </div>
            </div>
            <div class="accordion-item mt-2">
              <h2 class="accordion-header fs-5" id="flush-headingTwo">
                <button class="accordion-button collapsed fs-5" type="button" data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                  UK Energy
                </button>
              </h2>
              <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample">
                <div class="accordion-body fs-5  text-start">We all care about the environment and
                  want cleaner air, and we can do this
                  in a strategic, affordable way. Yet the
                  Westminster Net Zero plan is making
                  us all net poorer whilst creating more
                  emissions overall as it outsources them
                  overseas. It is therefore net stupid
                </div>
              </div>
            </div>
            <div class="accordion-item mt-2">
              <h2 class="accordion-header fs-5" id="flush-headingThree">
                <button class="accordion-button collapsed fs-5" type="button" data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                  More Money For Workers
                </button>
              </h2>
              <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample">
                <div class="accordion-body fs-5 text-start">Free up 6 million people from paying
                  Income Tax by lifting the minimum
                  threshold to £20,000 from £12,571 p.a.
                  This amounts to 1 in 5 taxpayers. Basic
                  Income Tax rate stays at 20%
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <SectionTitle title="Reform UK Policy"/>
      <div class="row mb-2 pt-5">
        <div v-for="policyItem in policy" :key="policyItem" class=" col-xs-12 col-sm-12 col-md-3 col-lg-3">
          <div class="card mr-2">
            <img :src="policyItem.image" class="card-img-top"  alt="{{ policyItem.title }}">
            <div class="card-body" style="text-align: left;">
              <h5 class="card-title">{{ policyItem.title }}</h5>
              <router-link style="text-align: left; left; text-decoration: none; color: black;;" :to="{
                name: 'article',
                params: {
                  article_id: policyItem.id,
                  article_slug: policyItem.title.replace(/[^a-zA-Z0-9]+/g, '-').toLowerCase()
                }
              }">
                Read More
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <MailChimp />

      <SectionTitle title="Latest News"/>
      <div class="row mb-2 pt-5">
        <div v-for="newsItem in news" :key="newsItem" class=" col-xs-12 col-sm-12 col-md-6 col-lg-4">
          <div class="card mr-2">
            <img :src="newsItem.image" class="card-img-top" style="height:250px;" alt="{{ newsItem.title }}">
            <div class="card-body" style="text-align: left;">
              <h5 class="card-title">{{ newsItem.title }}</h5>
              <p class="card-text fs-start">{{ newsItem.lead_title }}</p>
              <router-link style="text-align: left; left; text-decoration: none; color: black;;" :to="{
                name: 'article',
                params: {
                  article_id: newsItem.id,
                  article_slug: newsItem.title.replace(/[^a-zA-Z0-9]+/g, '-').toLowerCase()
                }
              }">
                Link to article
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
@font-face {
  font-family: "Neuton Extra Bold";
  src: url(~@/assets/fonts/Neuton-ExtraBold.ttf);
}

@font-face {
  font-family: "Shadows Into Light Two";
  src: url(~@/assets/fonts/ShadowsIntoLightTwo-Regular.ttf);
}

.accordion-button {
  background-color: #00B1DA !important;
  color: white !important;
}

.accordion-button.collapsed {
  background-color: whitesmoke !important;
  color: slategray !important;
}
</style>
<script setup>
import { ref } from "vue";
import axios from 'axios';
import MailChimp from "../components/MailChimp.vue";
import SectionTitle from "../components/SectionTitle.vue";
import { useHead } from '@vueuse/head';

useHead({
      title: `Jason Moorcroft Reform UK`,
      description: `Jason Moorcroft Reform UK Runcorn Frodsham Helsby`,
      keywords: `Reform UK Runcorn Frodsham Helsby Jason Moorcroft Vote Election`,
      meta: [
        { name: 'description', content: 'Jason Moorcroft Reform UK' },
        { property: 'og:title', content: 'Jason Moorcroft Official Website Reform UK Frodsham Runcorn Helsby' },
      ],
    });

let news = ref({});
let policy = ref({});

getNews();
getPolicy();

function getNews() {
  {
    axios
      .get('https://api.jasonmoorcroft.co.uk/api/articles?front=true&type=news')
      .then(response => {
        news.value = response.data.data;
      });
  }
}

function getPolicy() {
  {
    axios
      .get('https://api.jasonmoorcroft.co.uk/api/articles?front=true&type=policy')
      .then(response => {
        policy.value = response.data.data;
      });
  }
}

</script>
