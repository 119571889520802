<template>
  <div class="text-white" style="margin-top: 200px; background-color: #00B1DA; overflow-x: hidden;">
    <div class="pt-5 pb-5">
      <div id="mc_embed_shell">
        <div id="mc_embed_signup">
          <form
            action="https://jasonmoorcroft.us21.list-manage.com/subscribe/post?u=801be4c6c496d1ae610ff8afa&amp;id=4e287fb9d1&amp;f_id=00dea1e1f0"
            method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate"
            target="_blank">
            <div id="mc_embed_signup_scroll">
              <div class="row">
                <div class="col-sm-12 col-md-6 text-center p-3">
                  <h2>Don't miss our monthly updates</h2>
                </div>
                <div class="col-sm-12 col-md-4 text-center p-3">
                  <div class="mc-field-group"><input type="email" name="EMAIL" class="required email form-control"
                      id="mce-EMAIL" required="" value="" placeholder="Email Address"></div>
                </div>
                <div class="col-sm-12 col-md-2 text-center p-3">
                  <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="btn btn-danger btn-lg"
                    value="Subscribe">
                  <input type="text" name="b_801be4c6c496d1ae610ff8afa_4e287fb9d1" tabindex="-1" value=""
                    style="display: none;">
                </div>
              </div>
              <div class="row">
                <div id="mce-responses" class="clear foot">
                  <div class="response" id="mce-error-response" style="display: none;"></div>
                  <div class="response" id="mce-success-response" style="display: none;"></div>
                </div>
                <div aria-hidden="true" style="position: absolute; left: -5000px;">

                </div>
              </div>
            </div>
          </form>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'MailChimp',
  props: {
    msg: String
  }
}
</script>

