import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Jason Moorcroft Official",
    },
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    meta: {
      title: "About | Jason Moorcroft",
    },
  },
  {
    path: "/cookies",
    name: "cookies",
    component: () =>
      import(/* webpackChunkName: "cookies" */ "../views/CookiesView.vue"),
    meta: {
      title: "Cookies Policy | Jason Moorcroft",
    },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () =>
      import(/* webpackChunkName: "privacy" */ "../views/PrivacyView.vue"),
    meta: {
      title: "Privacy Statement | Jason Moorcroft",
    },
  },
  {
    path: "/accessibility",
    name: "accessibility",
    component: () =>
      import(
        /* webpackChunkName: "accessibility" */ "../views/AccessibilityView.vue"
      ),
    meta: {
      title: "Accessibility | Jason Moorcroft",
    },
  },
  {
    path: "/frodsham",
    name: "frodsham",
    component: () =>
      import(/* webpackChunkName: "frodsham" */ "../views/FrodshamView.vue"),
    meta: {
      title: "Frodsham | Jason Moorcroft",
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/ContactView.vue"),
    meta: {
      title: "Contact | Jason Moorcroft",
    },
  },
  {
    path: "/news",
    name: "news",
    component: () =>
      import(/* webpackChunkName: "news" */ "../views/NewsView.vue"),
    meta: {
      title: "News | Jason Moorcroft",
    },
  },
  {
    path: "/article/:article_id/:article_slug",
    name: "article",
    component: () =>
      import(/* webpackChunkName: "article" */ "../views/ArticleView.vue"),
    meta: {
      title: 'Article',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next();
});
export default router;
